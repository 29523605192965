import { Injectable } from '@angular/core';

import { ApiClient } from './apiClient.service';
import { Observable } from 'rxjs';
import { IMarppAccessData } from '../models/IMarppAccessData';

@Injectable()
export class MarppAccessService {
    basePath = '/marppAccess';

    constructor(private apiClient: ApiClient) {}

    public setMarppAccessData(clientId: string, scope: string): Observable<unknown> {
        const path = `${this.basePath}/${clientId}?scope=${scope}`;
        return this.apiClient.post(path, {});
    }

    public getMarppAccessData(clientId: string, scope: string): Observable<IMarppAccessData> {
        const path = `${this.basePath}/${clientId}?scope=${scope}`;
        return this.apiClient.get(path);
    }
}
